<template>
  <div>
    <base-input v-model="localValue" :placeholder="placeholder" @keyup.enter="$emit('success')" :disabled="isSending" />

    <div v-if="isLoading" class="m-4">
      <vue-lottie-player
        name="scooterAnim"
        loop
        :animation-data="require('@/assets/animations/spinner.json')"
        class="ml-auto mr-auto"
        style="height: 100px"
      />

      <p class="text-center">
        <em class="text-white"> {{ "Searching for keywords..." | __t("status__search-for-keywords") }}</em>
      </p>
    </div>

    <div
      v-for="(suggestion, index) in suggestions"
      :key="index"
      class="mt-3 p-3 bg-transparent-success rounded-top-right-lg rounded-bottom-right-lg"
    >
      <b-button
        @click="handleUseSuggestion(index, suggestion.keyword)"
        variant="success"
        class="float-right"
        :disabled="isLoading"
        pill
      >
        {{ "Add Keyword" | __t("button__add-keyword") }}
      </b-button>

      <p v-html="suggestion.keyword" class="mb-0" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_JOB } from "@/store/actions";

import { debounce } from "lodash";
import { BButton } from "bootstrap-vue";
import VueLottiePlayer from "vue-lottie-player";

import { TOOLS, SUGGESTIONS_IDS } from "@/constants/tasks";

import BaseInput from "./BaseInput.vue";

export default {
  name: "SeoKeywords",
  components: { BButton, VueLottiePlayer, BaseInput },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    localState: {
      type: Object,
      default: () => ({}),
    },
    isSending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    taskId() {
      return this.$route.params.taskId;
    },

    formData() {
      return this.localState.context.formData;
    },
    description() {
      return this.formData.description;
    },
  },
  data: () => ({
    localValue: "",

    suggestions: [],

    isLoading: false,
  }),
  mounted() {
    this.localValue = this.value;
    this.debouncedSuggestKeywords = debounce(this.suggestKeywords, 2000);

    this.$watch("description", (newVal) => {
      if (newVal) {
        this.debouncedSuggestKeywords();
      }
    });
  },
  watch: {
    localValue(newValue) {
      this.$emit("update:value", newValue);
      this.$emit("input", newValue);
    },
  },
  methods: {
    ...mapActions("projectsTasksTools", { createJob: CREATE_JOB }),
    async suggestKeywords() {
      this.suggestions = [];
      this.isLoading = true;

      const { projectId = "visitor", taskId = "visitor", description } = this;

      const tasksList = [
        {
          id: "suggestions",
          status: false,
          text: "Suggest Keywords",
          handler: TOOLS.RUN_SUGGEST,
          args: { suggestionId: SUGGESTIONS_IDS.SUGGEST_BLOG_POST_KEYWORDS, description },
        },
      ];

      const { suggestionsList } = await this.createJob({
        projectId,
        taskId,
        tasksList,
        isSendingMessage: false,
        isLockingTask: false,
      });

      this.suggestions = [...suggestionsList];
      this.isLoading = false;
    },

    handleUseSuggestion(index, keyword) {
      const { localValue } = this;
      this.localValue = localValue ? `${this.localValue}, ${keyword}` : keyword;

      this.suggestions.splice(index, 1);
    },
  },
};
</script>
